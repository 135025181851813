@use "variables/colors";
@use "variables/typography";

h1:not([cds-text]){
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-h1-font-size;
  line-height: typography.$id37-h1-line-height;
  font-weight: typography.$id37-h1-font-weight;
  color: typography.$id37-h1-color;
  margin: typography.$id37-h1-margin;
}

h2:not([cds-text]){
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-h2-font-size;
  line-height: typography.$id37-h2-line-height;
  font-weight: typography.$id37-h2-font-weight;
  color: typography.$id37-h2-color;
  margin: typography.$id37-h2-margin;
}

h3:not([cds-text]){
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-h3-font-size;
  line-height: typography.$id37-h3-line-height;
  font-weight: typography.$id37-h3-font-weight;
  color: typography.$id37-h3-color;
  margin: typography.$id37-h3-margin;
}

h4:not([cds-text]){
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-h4-font-size;
  line-height: typography.$id37-h4-line-height;
  font-weight: typography.$id37-h4-font-weight;
  color: typography.$id37-h4-color;
  margin: typography.$id37-h4-margin;
}

h5:not([cds-text]){
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-h5-font-size;
  line-height: typography.$id37-h5-line-height;
  font-weight: typography.$id37-h5-font-weight;
  color: typography.$id37-h5-color;
  margin: typography.$id37-h5-margin;
}

h6:not([cds-text]){
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-h6-font-size;
  line-height: typography.$id37-h6-line-height;
  font-weight: typography.$id37-h6-font-weight;
  color: typography.$id37-h6-color;
  margin: typography.$id37-h6-margin;
}

h1, h2, h3, h4, h5, h6 {
  word-break: break-word;
}

pre:not([cds-text]) {
  font-family: typography.$id37-font-family;
  min-width: unset;
  max-width: unset;
  width: auto;
  min-height: unset;
  max-height: unset;
  height: auto;
  border: none;
  background-color: #F8F8F8;
}

p:not([cds-text]), .paragraph {
  font-family: typography.$id37-font-family;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  margin-top: 0;
  margin-bottom: 1em;
}

.id37-text1 {
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-text1-font-size;
  line-height: typography.$id37-text1-line-height;
  font-weight: typography.$id37-text1-font-weight;
  color: typography.$id37-text1-color;
}

.id37-text2 {
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-text2-font-size;
  line-height: typography.$id37-text2-line-height;
  font-weight: typography.$id37-text2-font-weight;
  color: typography.$id37-text2-color;
}

.id37-text3 {
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-text3-font-size;
  line-height: typography.$id37-text3-line-height;
  font-weight: typography.$id37-text3-font-weight;
  color: typography.$id37-text3-color;
}

.id37-text4 {
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-text4-font-size;
  line-height: typography.$id37-text4-line-height;
  font-weight: typography.$id37-text4-font-weight;
  color: typography.$id37-text4-color;
}

.id37-text5 {
  font-family: typography.$id37-font-family;
  font-size: typography.$id37-text5-font-size;
  line-height: typography.$id37-text5-line-height;
  font-weight: typography.$id37-text5-font-weight;
  color: typography.$id37-text5-color;
}

b:not([cds-text]), strong:not([cds-text]) {
  font-weight: 600;
}

.text-normal {
  font-style: normal;
}

.text-italic {
  font-style: italic;
}

.text-light {
  font-weight: 100;
}

.text-regular {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: 700;
}

.text-black {
  font-weight: 900;
}

a:not([cds-text]), a:link, .id37-link {
  color: colors.$id37-brand-color;
  font-weight: typography.$id37-link-font-weight;
  cursor: pointer;

  &:hover, &:active, &:visited {
    color: colors.$id37-brand-color;
    text-decoration: none;
    cursor: pointer;
  }

  &.no-underline {
    text-decoration: none;
  }
}

ul:not([cds-text]), ol:not([cds-text]) {
  list-style-position: outside;
  padding-left: 1.5em;
  color: colors.$id37-dark-grey-text-color;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;

  li {
    margin: 15px 0;
  }

  &.bold-items {
    li {
      font-weight: 500;
    }
  }
}
