@use "../variables/colors";

.unzerSandboxNotify {
  display: block;
  position: fixed;
  bottom: 0;
  font-size: 0.6rem;
  padding: 0.1em;
  margin: 0;
  background-color: colors.$id37-brand-color;

  p {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 1em;
    line-height: 1em;
    font-weight: normal;
  }

  /* If there are multiple unzerSandboxNotify divs, only the first one should be shown. */
  & + .unzerSandboxNotify {
    display: none;
  }
}
