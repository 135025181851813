@import "colors";

$id37-input-font-size: 0.8em; // 16px @ 20px/rem
$id37-input-line-height: 1em; // 16py @ 16px/rem
$id37-input-font-weight: normal;
$id37-input-height: 3.375em; // 54px @ 16px/rem
$id37-input-padding: 0.8em 1.25em;
$id37-input-margin: 0;
$id37-input-background-color: $id37-grey-0;
$id37-input-text-color: $id37-dark-grey-text-color;
$id37-input-placeholder-color: $id37-medium-grey-text-color;
$id37-input-border-size: 0;
$id37-input-bottom-border-size: 1px;
$id37-input-border-color: $id37-brand-color;
$id37-input-placeholder-color: $id37-medium-grey;
