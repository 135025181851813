$datagrid-background-color: #FAFAFA; // #f6f7fb ?

.datagrid-overlay-wrapper {
  overflow: initial;

  .datagrid-scroll-wrapper {
    overflow: auto;
    overflow-y: hidden;
  }
}

.datagrid {
  border: none;
  margin: 0;

  .datagrid-head, .datagrid-header {
    border-bottom: 20px solid $datagrid-background-color;

    .datagrid-column {
      padding: 7px 2px;
    }
  }

  .datagrid-body {
    overflow: initial;

    .datagrid-row {
      border: none;
      border-bottom: 6px solid $datagrid-background-color;

      .datagrid-cell {
        padding: 2px;
      }
    }
  }

  .datagrid-placeholder {
    background-color: $datagrid-background-color;
    border: none;

    &.datagrid-empty /*.datagrid-placeholder-image*/
    {
      display: none;
    }
  }
}

.datagrid-foot, .datagrid-footer {
  display: flex;
  height: auto;
  border: none;
  background-color: $datagrid-background-color;
  padding: 0;

  .pagination-description {
    margin: 5px 0;
  }

  .pagination-list {
    display: none;
  }
}

.datagrid-column-handle {
  pointer-events: none;
  visibility: hidden;
}

.datagrid-column-separator {
  pointer-events: none;
  visibility: hidden;
}

.datagrid .datagrid-column.asc .datagrid-column-flex::after,
.datagrid .datagrid-column.desc .datagrid-column-flex::after,
.datagrid .datagrid-head .asc.datagrid-row-actions .datagrid-column-flex::after,
.datagrid .datagrid-head.desc .datagrid-row-actions .datagrid-column-flex::after {
  display: none;
}

.datagrid .datagrid-column .datagrid-column-title,
.datagrid .datagrid-head .datagrid-row-actions .datagrid-column-title {
  flex: none;
}

.datagrid .datagrid-column clr-dg-filter,
.datagrid .datagrid-head .datagrid-row-actions clr-dg-filter,
.datagrid .datagrid-column clr-dg-string-filter,
.datagrid .datagrid-head .datagrid-row-actions clr-dg-string-filter {
  margin-left: 0;
  vertical-align: middle;
}

.datagrid .datagrid-column .datagrid-filter-toggle,
.datagrid .datagrid-head .datagrid-row-actions .datagrid-filter-toggle {
  display: none;
  margin-left: 0;
  margin-top: 25%;
}

.datagrid-table .datagrid-cell:focus {
  outline: 0;
  outline-offset: 0;
}
