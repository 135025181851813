/* FRIENDS-SPACING */
$big-space: 3rem; // 60px
$headline-space: 1.5rem; // 30px
$text-space: 1rem; // 20px

.orange-arrow {
  margin-right: 0.5em;
  width: 1.1em;
}

.big-space-bottom {
  margin-bottom: $big-space;
}

.headline-space-bottom {
  margin-bottom: $headline-space;
}

.motive-wrapper {
  margin-bottom: $big-space;

  .motive-text {
    margin: $headline-space 0;
  }
}

.friends-collapsible-item {
  margin: 0.5em 0;
}

