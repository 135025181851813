@use "../variables/breakpoints";
@use "../variables/colors";
@use "../variables/layout";

.content-container {
  height: calc(100vh - #{layout.$id37-top-gradient-height + layout.$id37-top-nav-height-mobile});
  min-height: calc(100vh - #{layout.$id37-top-gradient-height + layout.$id37-top-nav-height-mobile});
  @media screen and (min-width: breakpoints.$id37-top-nav-mobile-breakpoint) {
    height: calc(100vh - #{layout.$id37-top-gradient-height + layout.$id37-top-nav-height-desktop});
    min-height: calc(100vh - #{layout.$id37-top-gradient-height + layout.$id37-top-nav-height-desktop});
  }

  .content-area {
    padding: 0;
    background-color: colors.$id37-background-color;
    float: left;
    width: 100%;
    height: 100%;
  }
}
