@use "variables/general";
@use "variables/colors";
@use "variables/inputs";

//noinspection CssReplaceWithShorthandSafely
input, input[type='text'], input[type='password'], input[type='date'], input[type='number'] {
  background: inputs.$id37-input-background-color;
  color: inputs.$id37-input-text-color;
  border: inputs.$id37-input-border-size;
  border-bottom: inputs.$id37-input-bottom-border-size;
  border-style: solid;
  border-color: transparent;
  border-radius: 0;
  font-weight: inputs.$id37-input-font-weight;
  font-size: inputs.$id37-input-font-size;
  min-height: inputs.$id37-input-height;
  height: inputs.$id37-input-height;
  padding: inputs.$id37-input-padding;
  margin: inputs.$id37-input-margin;
  min-width: unset;
  width: 100%;
  transition: none;
  box-shadow: none;

  &::-webkit-input-placeholder {
    color: inputs.$id37-input-placeholder-color;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: inputs.$id37-input-placeholder-color;
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: inputs.$id37-input-placeholder-color;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    color: inputs.$id37-input-placeholder-color;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: inputs.$id37-input-placeholder-color;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: inputs.$id37-input-placeholder-color;
  }

  &:focus, &:active {
    background-color: inputs.$id37-input-background-color;
    border-color: inputs.$id37-input-border-color;
  }

  &:focus:not([readonly]), &:active {
    border-color: inputs.$id37-input-border-color;
  }

  &.error {
    background-color: colors.$id37-error-color-light;
    border-color: colors.$id37-error-color;
    /*
    &:focus, &:active {
      border-color: $id37-error-color;
    }
    */
  }

  &.disabled {
    user-select: none;
    cursor: not-allowed;
    color: colors.$id37-light-grey-text-color;
  }
}

input[type='checkbox'] {
  width: auto;
}

input[type='radio'] {
  min-height: unset;
}

input::-webkit-calendar-picker-indicator {
  // Removes arrow from datalist.
  display: none;
}

label {
  display: inline-flex;
  color: colors.$id37-grey-3;
  font-size: 0.7em;
  line-height: 1em;
  font-weight: bold;
  margin-bottom: 0.7em;
}

.approval {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  label {
    display: block;
    text-align: justify;
  }
}

textarea {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  min-height: 15em;
  border: none;
  box-shadow: general.$id37-box-shadow;
  padding: 1em;
  font-size: inputs.$id37-input-font-size;
  font-weight: inputs.$id37-input-font-weight;
}

label.id37-radio-label {
  display: flex;
  color: colors.$id37-grey-6;
  align-items: center;
  cursor: pointer;
  font-weight: normal;

  input[type='radio'] {
    display: none;
  }

  .id37-radio-button {
    width: 1.5em;
    height: 1.5em;
    border: 1px solid colors.$id37-brand-color;
    border-radius: 50%;
    margin-right: 15px;
    box-sizing: border-box;
    padding: 6px;
  }

  .id37-radio-button::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: colors.$id37-grey-6;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.15s;
  }

  input[type='radio']:checked + .id37-radio-button::after {
    transform: scale(1);
  }
}
