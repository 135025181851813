.select::after {
  display: none;
}

.select {
  background: url('/src/assets/icons/arrow_upDown_20x20.svg') no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
}

.select select:hover,
.select select:active {
  border-color: transparent;
  background: none;
}
