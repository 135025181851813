@use "variables/colors";

clr-dropdown {
  clr-dropdown-menu,
  clr-dropdown-menu.dropdown-menu {
    border: 0;
    box-shadow: 0 1px 0.4rem rgba(115, 115, 115, 0.25);
    background-color: colors.$id37-background-color;
    padding: 0.5em 1em;
    border-radius: 2px;
    margin-top: 0.1em;

    .dropdown-item, a:not(.id37-link), a:link:not(.id37-link), a.dropdown-item:not(.id37-link) {
      color: colors.$id37-dark-grey-text-color;
      font-weight: normal;

      &:active, &:hover, &:visited {
        color: colors.$id37-dark-grey-text-color;
        font-weight: normal;
      }
    }
  }
}

.clr-checkbox-wrapper {
  input[type='checkbox'] {
    min-height: unset;
    margin: 0 !important;
  }

  input[type='checkbox']:checked + label::before {
    box-shadow: none;
    outline: none;
  }
}

.clr-form-group {
  margin-top: 0.75em;
}

clr-datagrid {
  color: colors.$id37-light-medium-grey;
}

clr-dg-row {
  border-top: 1.5px solid colors.$id37-light-medium-grey;
}

