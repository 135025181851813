/*
    BasisGrotesquePro-Light:   font-weight 100-300
    BasisGrotesquePro-Regular: font-weight 400
    BasisGrotesquePro-Medium:  font-weight 500
    BasisGrotesquePro-Bold:    font-weight 600-700
    BasisGrotesquePro-Black:   font-weight 800-900
 */

@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-Light.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: italic;
  font-weight: 100;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-LightItalic.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-Light.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: italic;
  font-weight: 200;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-LightItalic.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-Light.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-LightItalic.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-RegularItalic.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-Medium.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-MediumItalic.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-Bold.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-BoldItalic.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-Bold.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-BoldItalic.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-Black.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: italic;
  font-weight: 800;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-BlackItalic.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-Black.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro';
  font-style: italic;
  font-weight: 900;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesquePro-BlackItalic.otf');
  font-display: swap;
}

/* BasisGrotesquePro-Mono */

@font-face {
  font-family: 'BasisGrotesquePro-Mono';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesqueProMono-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro-Mono';
  font-style: italic;
  font-weight: 100;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesqueProMono-RegularItalic.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesquePro-Mono';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesqueProMono-Bold.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro-Mono';
  font-style: italic;
  font-weight: 800;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesqueProMono-BoldItalic.otf');
  font-display: swap;
}

@font-face {
  font-family: 'BasisGrotesquePro-OffWhite';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesqueProOffWhite-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: 'BasisGrotesquePro-OffWhite';
  font-style: italic;
  font-weight: 100;
  src: url('/assets/fonts/basis_grotesque_pro/BasisGrotesqueProOffWhite-RegularItalic.otf');
  font-display: swap;
}
