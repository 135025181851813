#close-sign {
  position: fixed;
  top: 4.5rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8500;
  border-radius: 10em;
  background-color: #ffd8c94f;

  @media screen and (max-width: 768px) {
    top: 3.8rem;
    right: 0.25rem;
    margin: 0;
  }

  img {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
}
