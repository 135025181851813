@use "variables/colors";

// TODO: Rename to id37-alert, make an alert-component.
.alert {
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  line-height: 1em;
  margin: 1em 0;
  padding: 1em;
  border: 0 solid transparent;
  border-radius: 0;
  background: none;

  &, &.default {
    border-color: colors.$id37-medium-grey;
    background-color: colors.$id37-light-grey;
    color: colors.$id37-dark-grey-text-color;
  }

  &.info {
    border-color: #c8d1dd;
    background-color: #e0eaf7;
    color: colors.$id37-dark-grey-text-color;
  }

  &.success {
    border-color: colors.$id37-success-color;
    background-color: colors.$id37-success-color-light;
    color: colors.$id37-success-color;
  }

  &.warning {
    border-color: colors.$id37-warning-color;
    background-color: colors.$id37-warning-color-light;
    color: colors.$id37-warning-color;
  }

  &.danger {
    border-color: colors.$id37-danger-color;
    background-color: colors.$id37-danger-color-light;
    color: colors.$id37-danger-color;
  }

  &.error {
    border-color: colors.$id37-error-color;
    background-color: colors.$id37-error-color-light;
    color: colors.$id37-error-color;
  }
}

