@use "colors";

$id37-btn-font-size: 0.8em;
$id37-btn-line-height: 1em;
$id37-btn-font-weight: 600;
$id37-btn-sm-font-size: 0.6em;
$id37-btn-sm-line-height: 1em;
$id37-btn-margin: 0.2em 0.2em 0.2em 0;
$id37-btn-padding: 1em 2em;
$id37-btn-sm-padding: 0.5em 1em;
$id37-btn-flat-padding: 0.75em 2em;
$id37-btn-flat-padding-mobile: 0.5em 1em;
$id37-bright-btn-text-color: colors.$id37-super-light-grey-text-color;
$id37-btn-border-size: 2px;
$id37-btn-background-color: colors.$id37-background-color;
$id37-btn-text-color: colors.$id37-dark-grey-text-color;

$id37-btn-outline-background-color: colors.$id37-background-color;
$id37-btn-outline-border-hover-color: colors.$id37-dark-grey;
$id37-btn-outline-disabled-background-color: colors.$id37-light-grey;
$id37-btn-outline-disabled-border-color: colors.$id37-grey-2;
$id37-btn-outline-disabled-text-color: colors.$id37-grey-4;
// TODO: styles for active(button pressed) effect ?

$id37-btn-outline-secondary-background-color: colors.$id37-background-color;
$id37-btn-outline-secondary-border-color: colors.$id37-grey-4;
$id37-btn-outline-secondary-text-color: colors.$id37-grey-4;
$id37-btn-outline-secondary-hover-border-color: colors.$id37-dark-grey;
$id37-btn-outline-secondary-disabled-background-color: colors.$id37-light-grey;
$id37-btn-outline-secondary-disabled-border-color: colors.$id37-grey-2;
// TODO: styles for active(button pressed) effect ?

$id37-primary-btn-background-color: colors.$id37-brand-color;
$id37-primary-btn-text-color: colors.$id37-background-color;
$id37-primary-btn-active-color: colors.$id37-brand-color-pressed;
$id37-primary-btn-hover-color: colors.$id37-brand-color-hover;
$id37-primary-btn-disabled-background-color: colors.$id37-brand-color-inactive;
$id37-primary-btn-disabled-text-color: colors.$id37-background-color;

$id37-secondary-btn-background-color: colors.$id37-grey-active;
$id37-secondary-btn-text-color: colors.$id37-dark-grey-text-color;
$id37-secondary-btn-active-color: colors.$id37-grey-pressed;
$id37-secondary-btn-hover-color: colors.$id37-grey-hover;
$id37-secondary-btn-disabled-background-color: colors.$id37-grey-inactive;
$id37-secondary-btn-disabled-text-color: colors.$id37-grey-3;

$id37-warning-btn-background-color: colors.$id37-warning-color-light;
$id37-warning-btn-text-color: colors.$id37-dark-grey-text-color;
$id37-warning-btn-border-color: colors.$id37-warning-color;

$id37-danger-btn-background-color: colors.$id37-danger-color-light;
$id37-danger-btn-text-color: colors.$id37-dark-grey-text-color;
$id37-danger-btn-border-color: colors.$id37-danger-color;
