$bubble-initial-font-size-lg: 0.75vw;
$bubble-initial-font-size-md: 1.5vw;
$bubble-initial-font-size-sm: 1.6vw;
$bubble-initial-font-size-xs: 2.6vw;

.eye {
  cursor: pointer;

  .eye-opened {
    display: none;
  }

  &:hover {
    .eye-closed {
      display: none;
    }

    .eye-opened {
      display: inline;
    }
  }

  &.show {
    .eye-opened {
      display: inline;
    }

    .eye-closed {
      display: none;
    }

    &:hover {
      .eye-opened {
        display: none
      }

      .eye-closed {
        display: inline;
      }
    }
  }
}
