@use "variables/typography";
@use "variables/colors";

/* The font to use */
@import 'assets/fonts/basis_grotesque_pro/stylesheet.css';

// Clarity Dependency SCSS
$clr-grid-columns: 12 !default;
$clr-font: 'BasisGrotesquePro';
@import '../node_modules/@clr/icons/clr-icons.min.css';
@import '../node_modules/@clr/ui/clr-ui.min.css';

/* ngx quill and modules */
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.bubble.css';
@import '../node_modules/quill/dist/quill.snow.css';

/* Overrides for plugins */
@import "plugin-customizations/clarity";
@import "plugin-customizations/datagrid";
@import "plugin-customizations/fontawesome";
@import "plugin-customizations/unzer";
@import "plugin-customizations/quill";

html:not([cds-text]), body:not([cds-text]) {
  width: 100%;
  /*
   * This is the font-size on which any scaling of this application is based on!
   * 1em => 20px, 0.1em => 2px, ...
   * This value can therefore be seen as the global scaling factor of the application.
   * We set this value specifically to overwrite any default set by a browser.
   */
  font-size: 20px;
  color: colors.$id37-dark-grey-text-color;
  font-family: typography.$id37-font-family;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
  outline: none;
}

.no-scroll {
  position: fixed;
  left: 0;
  right: 0;
  &.keep-vertical-scrollbar {
    overflow-y: scroll; /* Render disabled scroll bar to keep the same width. */
  }
  &.keep-horizontal-scrollbar {
    overflow-y: scroll; /* Render disabled scroll bar to keep the same width. */
  }
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

@import "alert";
@import "button";
@import "card";
@import "helper";
@import "iframe";
@import "input";
@import "select";
@import "table";
@import "typograpy";

@import "content/content-container";
@import "content/geometry";
@import "content/gradient";
@import "content/dashboard";
@import "content/friends";
@import "content/close-sign";
@import "content/teams";

// TODO: Remove and use alert instead!
.error-tooltip {
  border-radius: 0;
  background: colors.$id37-error-color;
  margin: 0 0 0.5em;

  div {
    color: white;
    font-size: 0.85em;
    line-height: 1em;
    padding: 0.4em;
  }
}

@media print {
  @import 'print';
}
