@use "variables/colors";

.arrow-up {
  width: 0;
  height: 0;
  border-left: 0.25em solid transparent;
  border-right: 0.25em solid transparent;
  border-bottom: 0.25em solid colors.$id37-dark-grey-text-color;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 0.25em solid transparent;
  border-right: 0.25em solid transparent;
  border-top: 0.25em solid colors.$id37-dark-grey-text-color;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 0.25em solid transparent;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid colors.$id37-dark-grey-text-color;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 0.25em solid transparent;
  border-bottom: 0.25em solid transparent;
  border-right: 0.25em solid colors.$id37-dark-grey-text-color;

  &.open {
    border: unset;

    .bar1 {
      background-color: red;
    }
    .bar2 {
      background-color: green;
    }
  }
}
