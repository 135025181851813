fa-icon {
  &.fit-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
      width: auto !important;
      height: auto !important;
    }
  }
}
