@use "colors";

$id37-font-family: BasisGrotesquePro, serif;

$id37-h1-font-size: 2.7em; // 54px @ 20px/rem
$id37-h1-line-height: 1.15em; // ~62px @ 54px/rem
$id37-h1-font-weight: 700;
$id37-h1-color: colors.$id37-dark-grey-text-color;
$id37-h1-margin: 0 0 0.5em 0;

$id37-h2-font-size: 1.8em; // 36px @ 20px/rem
$id37-h2-line-height: 1em; // ~36px @ 36px/rem
$id37-h2-font-weight: 700;
$id37-h2-color: colors.$id37-dark-grey-text-color;
$id37-h2-margin: 0 0 0.5em 0;

$id37-h3-font-size: 1.5em; // 30px @ 20px/rem
$id37-h3-line-height: 1.3333em; // ~40px @ 30px/rem
$id37-h3-font-weight: 700;
$id37-h3-color: colors.$id37-dark-grey-text-color;
$id37-h3-margin: 0 0 0.5em 0;

$id37-h4-font-size: 1.2em; // 24px @ 20px/rem
$id37-h4-line-height: 1.3334em; // ~32px @ 24px/rem
$id37-h4-font-weight: 700;
$id37-h4-color: colors.$id37-dark-grey-text-color;
$id37-h4-margin: 0 0 0.5em 0; // 12px

$id37-h5-font-size: 0.9em; // 18px @ 20px/rem
$id37-h5-line-height: 1.3334em; // ~32px @ 24px/rem
$id37-h5-font-weight: 700;
$id37-h5-color: colors.$id37-dark-grey-text-color;
$id37-h5-margin: 0 0 0.5em 0; // 18px

$id37-h6-font-size: 0.8em; // 16px @ 20px/rem
$id37-h6-line-height: 1em; // ~16px @ 16px/rem
$id37-h6-font-weight: 700;
$id37-h6-color: colors.$id37-dark-grey-text-color;
$id37-h6-margin: 0 0 0.5em 0;

$id37-text1-font-size: 1em; // 20px @ 20px/rem
$id37-text1-line-height: 1.5em; // ~30px @ 20px/rem
$id37-text1-font-weight: normal;
$id37-text1-color: colors.$id37-dark-grey-text-color;

$id37-text2-font-size: 0.9em; // 18px @ 20px/rem
$id37-text2-line-height: 1.4444em; // ~26px @ 18px7rem
$id37-text2-font-weight: normal;
$id37-text2-color: colors.$id37-dark-grey-text-color;

$id37-text3-font-size: 0.8em; // 16px @ 20px/rem
$id37-text3-line-height: 1.375em; // ~22px @ 16px/rem
$id37-text3-font-weight: normal;
$id37-text3-color: colors.$id37-dark-grey-text-color;

$id37-text4-font-size: 0.7em; // 14px @ 20px/rem
$id37-text4-line-height: 1.429em; // ~20px @ 14px/rem
$id37-text4-font-weight: normal;
$id37-text4-color: colors.$id37-dark-grey-text-color;

$id37-text5-font-size: 0.6em; // 12px @ 20px/rem
$id37-text5-line-height: 1.429em; // ~20px @ 14px/rem
$id37-text5-font-weight: normal;
$id37-text5-color: colors.$id37-dark-grey-text-color;

$id37-link-font-weight: 600;
