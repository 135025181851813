/**
 * General styles which should be applied when printing the site can be applied here.
 */
.hide-on-print {
  display: none !important;
}

app-logo {
  -webkit-print-color-adjust: exact;
  //noinspection CssUnknownProperty
  print-color-adjust: exact;
  font-size: 0.8em;
}

app-nav {
  display: none !important;
}

.svg-wrapper {
  width: 90%;
}

app-report {
  #close-report {
    display: none !important;
  }

  .sidebar {
    display: none !important;
  }

  .report-content {
    width: 100% !important;
    border: none !important;
    float: unset !important;
  }

  .report {
    margin: 0 !important;
    padding: 0 !important;

    .report-element {
      &.spacing-top {
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .profile {
      page-break-after: always !important;
    }

    .interpretation,
    .profile-intro,
    .how-motives-work,
    .explanation-motives,
    .science,
    .buy-book {
      page-break-before: always !important;
    }

    .key-graphic.main-key-graphic {
      margin: 0 !important;
      padding: 0 !important;
      page-break-before: avoid !important;
      page-break-after: avoid !important;
    }

    // Every motive description should start at a new page.
    .motive {
      margin-top: 0 !important;
      padding: 0 !important;
      page-break-before: always !important;
    }

    // Never print the client-info component (only seen by masters)
    // and the print-document component, which contains no information.
    .client-info,
    .print-document {
      display: none !important;
    }

    app-collapsible-item {
      app-collapsible-header {
        border: none !important;
        padding: 0 !important;
        .icon {
          display: none !important;
        }
      }

      // Every collapsible element must be visible. -> Undo any styling which was applied from the animation.
      app-collapsible-body {
        height: auto !important;
        padding: initial !important;
        overflow: inherit !important;
      }
    }

  }

  // Styling if only the "motive profile" should be printed.
  .print-motive-profile .report {
    /* Deactivate ... */
    .client-info,
    .intro,
    .interpretation,
    .profile,
    .profile-intro,
    .profile-narrowings,
    .profile-special,
    .profile-example,
    .key-graphic.example-key-graphic,
    .profile-application,
    .explanation-motives,
    .science,
    .graphic-gauss,
    .how-motives-work,
    .motives-description-text,
    .motive,
    .find-master,
    .buy-book,
    .contact {
      display: none !important;
    }
  }

  // Styling if the whole report should be printed.
  .print-report .report {
    /* Print everything of the report! */
  }

  .print-button {
    display: none !important;
  }
}

app-team-body {
  .static-text {
    display: none !important;
  }
}
