.iframe {
  width: 100%;
  // This kills the iframe from unzer(heidelpay) if needed replace with more specific css selector.
  min-height: 25em;
  background-color: white;
  box-shadow: none;
  outline: none;
  border: none;
  overflow: auto;
}
