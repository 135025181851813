@use 'variables/colors';

$dashboard-btn-width: 13em;
$dashboard-btn-height: 12em;
$dashboard-btn-border-color: #E9E9E9;
$dashboard-btn-background-color: #F8F8F8;
$dashboard-btn-margin: 0.75em;
$dashboard-btn-padding: 0.5em;
$dashboard-btn-hover-scale: 1.06;
$dashboard-btn-hover-border-color: $dashboard-btn-border-color;

.dashboard-btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: (2 * $dashboard-btn-width + 2 * $dashboard-btn-margin + 6em)) {
    justify-content: center;
  }
}

.dashboard-btn-wrapper {
  display: flex;
  flex-direction: column;
  transition: transform .15s ease;
  margin: 0 $dashboard-btn-margin $dashboard-btn-margin 0;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: (2 * $dashboard-btn-width + 2 * $dashboard-btn-margin + 6em)) {
    width: 95%;
    margin-right: 0;
  }

  &:hover {
    border-color: $dashboard-btn-hover-border-color;
    transform: scale($dashboard-btn-hover-scale);
  }

  .stoerer-icon {
    height: 2.2em;
    width: 2.2em;
    background-color: colors.$id37-brand-color;
    position: absolute;
    align-self: flex-end;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .stoerer-text {
      font-size: 0.7em;
      line-height: 0.8em;
      text-align: center;
      font-weight: 500;
    }
  }

  .dashboard-btn {
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;
    background-color: $dashboard-btn-background-color;
    border: 1px solid $dashboard-btn-border-color;
    margin: 0;
    padding: $dashboard-btn-padding;
    width: $dashboard-btn-width;
    height: $dashboard-btn-height;
    border-radius: 0;
    font-size: 0.9em;

    &:hover { // to disable &:hover:enabled in button.scss
      border-color: $dashboard-btn-hover-border-color;
    }

    @media screen and (max-width: (2 * $dashboard-btn-width + 2 * $dashboard-btn-margin + 6em)) {
      width: 100%;
      margin-right: 0;
    }

    .icon-svg {
      color: colors.$id37-dark-grey;
      width: 6em;
      height: 6em;
      float: none;
      margin-bottom: 0.75em;
    }

    app-profile-picture {
      width: 6em;
      height: 6em;
      border-radius: 0;
      margin-bottom: 0.75em;
    }

    .icon-text {
      display: block;
      white-space: initial;
      color: colors.$id37-dark-grey-text-color;
      font-size: 1.1em;
      line-height: 1.1em;
      margin: 0;
    }
  }
}


