body {
  &.noScroll {
    position: fixed;
    overflow: hidden;
  }
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.display-flex {
  display: flex;
}

.aligned {
  @extend .display-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.align-left {
    justify-content: flex-start;
  }
  &.align-center {
    justify-content: center;
  }
  &.align-right {
    justify-content: flex-end;
  }

  &.align-column {
    flex-direction: column;

    &.align-left {
      align-items: flex-start;
    }
    &.align-center {
      align-items: center;
    }
    &.align-right {
      align-items: flex-end;
    }
    .align-top {
      align-self: flex-start;
    }
  }

  &.align-column-reverse {
    flex-direction: column;

    &.align-left {
      justify-content: flex-start;
    }
    &.align-right {
      justify-content: flex-end;
    }
    .align-top {
      align-self: flex-end;
    }
  }

  &.align-row {
    flex-direction: row;

    &.align-left {
      justify-content: flex-start;
    }
    &.align-right {
      justify-content: flex-end;
    }
    &.align-top {
      align-items: flex-start;
    }
  }

  &.align-row-reverse {
    flex-direction: row-reverse;

    &.align-left {
      justify-content: flex-end;
    }
    &.align-right {
      justify-content: flex-start;
    }
  }
}

.position-relative {
  position: relative;
}

.full-height {
  height: 100%;
}

.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}
