@use "core/mixins";
@use "variables/colors";

$table_background_color: white;
$table_background_color_hover: darken(white, 10%);

$id37-table-border-color: lighten(colors.$id37-dark-grey, 80%);

$id37-order-by-color: darken(white, 8%);
$id37-column-background-if-ordered: $id37-order-by-color;

$table_header_cell_padding: .7em;
$table_body_cell_padding: .7em;

.id37-table-wrapper {
  width: 100%;
  overflow-x: auto;
  transition: height 0.5s ease;

  .id37-table {
    margin: 0;
    width: 100%;
    font-size: 1em;
    line-height: 1.35;

    // On th's and td's.
    th, td {
      &.id37-column-ordered {
        background-color: $id37-column-background-if-ordered;
      }
    }

    // Only on th's.
    th {
      padding: $table_header_cell_padding;

      &.id37-column-header {
        vertical-align: top;
        @include mixins.user-select-none;
      }
    }

    // Only on td's
    td {
      padding: $table_body_cell_padding;
      //noinspection CssOverwrittenProperties
      //text-align: left; // Backwards compatibility.
      //noinspection CssOverwrittenProperties
      //text-align: start; // Modern approach.
      vertical-align: top;

      &.id37-inlineedit {
        /*white-space: nowrap;*/
      }

      .id37-inlineedit-btn {
        visibility: hidden;
        transition: unset;
        vertical-align: top;
      }

      &.id37-btn-column {
        width: 1%;
        white-space: nowrap;
      }
    }

    &.id37-table-bordered {
      border-collapse: collapse;

      td, th {
        border: .05em solid $id37-table-border-color;
      }

      th {
        border-bottom: .15em solid $id37-table-border-color;
      }
    }

    &.id37-table-striped {
      tr:nth-child(2n) {
        background-color: #ffffeb;
      }
    }

    &.id37-table-hoverable, &.id37-table-row-hoverable {
      tbody tr:hover {
        background-color: $id37-table-border-color;
      }
    }

    &.id37-table-hoverable, &.id37-table-cell-hoverable {
      td {
        &:hover {
          box-shadow: 2px 2px 15px -2px rgb(50, 50, 50);
        }
      }
    }
  }
}

// Table column header..
.id37-column-header {

  &.id37-column-ordered {
  }

  .id37-column-header-main-row {
    @include mixins.display-flex;

    .id37-order-by-sign {
      font-size: 1em;
      font-weight: 400;
      margin-right: .3em;
    }

    .id37-column-name {
    }
  }

  .id37-column-info {
    font-weight: 400;
    font-size: .85em;
    margin-top: .3em;
  }
}

.id37-order-by-trigger {
  cursor: pointer;

  &:hover {
    background-color: $id37-order-by-color;
  }
}
