// new colors for primary button
$id37-brand-color: #FF5711;
$id37-brand-color-hover: #E54E0F;
$id37-brand-color-pressed: #CD460D;
$id37-brand-color-inactive: rgba($id37-brand-color, 0.4);

// new colors for secondary button
$id37-grey-active: #F5F5F7;
$id37-grey-hover: #DCDCDD;
$id37-grey-pressed: #C5C5C6;
$id37-grey-inactive: #E7E7E7;


// old colors

$id37-grey-7: #1A2126;
$id37-grey-6: #212121;
$id37-grey-5: #2E2F33;
$id37-grey-4: #5D6E77;
$id37-grey-3: #8D9CA3;
$id37-grey-2: #DBDBDA;
$id37-grey-1: #ECECEB;
$id37-grey-0: #F8F8F8;

$id37-super-dark-grey: $id37-grey-7;
$id37-dark-grey: $id37-grey-6;
$id37-medium-grey: $id37-grey-4;
$id37-light-medium-grey: #B3BAC5;
$id37-light-grey: $id37-grey-2;
$id37-super-light-grey: $id37-grey-1;

$id37-super-dark-grey-text-color: $id37-super-dark-grey;
$id37-dark-grey-text-color: $id37-dark-grey;
$id37-medium-grey-text-color: $id37-medium-grey;
$id37-light-medium-grey-text-color: $id37-light-medium-grey;
$id37-light-grey-text-color: $id37-light-grey;
$id37-super-light-grey-text-color: $id37-super-light-grey;

$id37-error-color: #fd7673;
$id37-error-color2: rgba(241, 54, 54, 0.15);
$id37-error-color-light: #FEEFF2;
$id37-danger-color: #FF0020;
$id37-danger-color-light: rgba($id37-danger-color, 0.1);
$id37-warning-color: #FDC01C;
$id37-warning-color-light: rgba($id37-warning-color, 0.1);
$id37-success-color: #4ac377;
$id37-success-color-light: rgba($id37-success-color, 0.1);

$id37-background-color: #FFFFFF;

// Referrer colors
$referrer-ww-color: #011380;
